/**
 * Init all Components for Shop
 */

import * as utils from "@sharedJS/utils";
import * as resize from "@sharedJS/resize";
import * as formElements from "@sharedJS/formElements";
import * as categoryBar from "@sharedJS/categoryBar";
import * as accordion from "@sharedJS/accordion";
import * as accordionNavigation from "@sharedJS/accordionNavigation";
import * as headerNavigation from "@sharedJS/headerNavigation";
import * as popUpOverlay from "@sharedJS/popUpOverlay";
import * as offcanvas from "@sharedJS/offcanvas";
import * as offcanvasSearch from "@sharedJS/offcanvasSearch";
import * as clearSearch from "@sharedJS/clearSearch";
import * as tooltip from "@sharedJS/tooltip";
import * as infoModals from "@sharedJS/infoModals";
import * as dropdown from "@sharedJS/dropdown";
import * as slider from "@sharedJS/slider";
import * as gotop from "@sharedJS/gotop";
import * as preventClick from "@sharedJS/preventClick";

import landingPage from "@sharedJS/landingpage";

import * as forgottenPassword from "@sharedJS/forgottenPassword";
import * as forgottenPasswordConfirm from "@sharedJS/forgottenPasswordConfirm";
import * as slot from "@sharedJS/slot";
import header from "@sharedJS/header";
import * as flyout from "@sharedJS/flyout";
import commonPopupTrigger from "@JS/shop/10-login-component/commonPopupTrigger";
import * as loyaltyMigration from "@sharedJS/loyaltyMigration";

import * as wishlistCount from "@shopJS/wishlistCount";

import * as teaserHeaderMore from "@sharedJS/teaserHeaderMore";

import * as cart from "@shopJS/cart";
import * as coupon from "@shopJS/coupon";
import * as popupMessagesLayer from "@sharedJS/popupMessagesLayer";
import * as previewCountryFlyout from "@shopJS/previewCountryFlyout";
import * as contactForm from "@sharedJS/contactForm";
import inspirationOverview from "@shopJS/inspirationOverview";
import * as scrollToLabel from "@sharedJS/scrollToLabel";

import * as registerConfirmation from "@sharedJS/registerConfirmation";
import * as dateMaskField from "@sharedJS/dateMaskField";

import { initAjaxPrefilter } from "@sharedJS/ajaxprefilter";
import * as modal from "@sharedJS/modal"; //
import * as logoAnimation from "@sharedJS/logoAnimation";
import * as lazyLoader from "@sharedJS/lazyLoader";
import * as seoLinkMask from "@sharedJS/seoLinkMask";

import * as epoqForeignInit from "@shopJS/epoqForeignInit";
import * as epoqCartData from "@shopJS/epoqCartData";
import * as svgIcons from "@sharedJS/svgIcons";

// General
initAjaxPrefilter();
utils.init();
resize.init();
formElements.init();
categoryBar.init();
accordion.init();
accordionNavigation.init();
headerNavigation.init();
popUpOverlay.init();
offcanvas.init();
offcanvasSearch.init();
clearSearch.init();
tooltip.init();
infoModals.init();

dropdown.init();
slider.init();
gotop.init();
preventClick.init();

landingPage.init();

// Login/Registration, Welcome-Layer
commonPopupTrigger.init(); //

//Accept Loyalty Terms and Conditions
loyaltyMigration.init();

// Password forgotten
forgottenPassword.init();
forgottenPasswordConfirm.init();
// // Department Home
slot.init();

// Header
header.init();
flyout.init();

// Wishlist
wishlistCount.init();

// Category
teaserHeaderMore.init();

// Cart
cart.init();
coupon.init();

// Popup Messages Layer
popupMessagesLayer.init();

// Preview
previewCountryFlyout.init();

// Contact form
contactForm.init();

// Inspiration
inspirationOverview.init();

// ScrollToLabel z.B. bei Anzugberater
scrollToLabel.init();

registerConfirmation.init();

// DateMaskField
dateMaskField.init();

logoAnimation.init();
modal.init();
lazyLoader.init();

seoLinkMask.init();
epoqForeignInit.init();
epoqCartData.init();

svgIcons.init();
